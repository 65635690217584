import React, { CSSProperties } from 'react'
import { COLORS, FONTS, FONT_WEIGHTS, FONT_SIZES, LINE_HEIGHTS } from '../../constants/theme'
import ReadMore from './ReadMore'
import useViewportType from '../../utils/useViewportType'

interface Props {
    doodle: string
}

const styles: { [key: string]: CSSProperties } | any = {
  commissionsWrapper: {
    marginTop: '48px'
  },
  commissionsTitle: (isMobile: boolean) => ({
    fontFamily: FONTS.serif,
    fontWeight: FONT_WEIGHTS.medium,
    color: COLORS.darkBlue,
    fontSize: isMobile ? '32px' : FONT_SIZES.desktopHeader,
    lineHeight: isMobile ? '36 px' : LINE_HEIGHTS.desktopHeader,
    width: isMobile ? '87.5vw' : '80vw',
    margin: 'auto'
  }),
  commissionsParagraph: (isMobile: boolean) => ({
    fontFamily: FONTS.sansSerif,
    fontWeight: FONT_WEIGHTS.mediumLight,
    color: COLORS.darkBlue,
    fontSize: isMobile ? '18px' : FONT_SIZES.desktopBody,
    lineHeight: isMobile ? '20px' : LINE_HEIGHTS.desktopBody,
    width: isMobile ? '87.5vw' : '80vw',
    margin: `${isMobile ? '12px' : '40px'} auto 0`,
    position: 'relative',
    zIndex: 1
  })
}

const Commissions = ({ doodle }: Props) => {
  const isMobile = useViewportType(700)

  return (
    <section style={styles.commissionsWrapper}>
      <h1 style={styles.commissionsTitle(isMobile)}>Commissions</h1>
      <h2 style={styles.commissionsParagraph(isMobile)}>
        Are you in search of a unique, tailor-made artwork just for you?
      </h2>
      <h2 style={styles.commissionsParagraph(isMobile)}>
        Commissioning artwork allows you to have a personalized piece created
        either for yourself or someone else.
      </h2>

      <ReadMore doodleLocation={doodle} buttonUri='/commissions' />
    </section>
  )
}

export default Commissions
