import React, { CSSProperties, Dispatch, SetStateAction } from 'react'
import { COLORS, FONT_WEIGHTS } from '../../../constants/theme'
import { handleAddToCart } from '../../../utils/singleProductUtils'

const styles: { [key: string]: CSSProperties } | any = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '50px'
  },
  button: {
    backgroundColor: COLORS.orange,
    color: COLORS.offWhite,
    fontWeight: FONT_WEIGHTS.medium,
    padding: '15px 60px',
    borderRadius: '100px'
  }
}

interface Props {
  product: any,
  cartId?: number,
  setCartId: Dispatch<SetStateAction<number>>,
  userId?: number,
  totalItems?: number
}

const AddToCartButton = ({ product, cartId, setCartId, userId }: Props) => {
  return (
    <div style={styles.wrapper}>
      <button
        style={styles.button}
        onClick={() => handleAddToCart(cartId, product, product.productType, setCartId, userId)}
      >
        Add to Cart
      </button>
    </div>
  )
}

export default AddToCartButton
