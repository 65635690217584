import React, { CSSProperties, useState, useEffect } from 'react'
import QuantityPicker from '../shared/QuantityPicker'
import AddToCartButton from '../shared/AddToCartButton'
import ReturnToPreviousButton from '../shared/ReturnToPreviousButton'
import {
  COLORS,
  FONTS,
  FONT_SIZES,
  FONT_WEIGHTS
} from '../../../constants/theme'
import { priceFormatter } from '../../../utils/priceFormatter'

const styles: { [key: string]: CSSProperties } | any = {
  price: {
    fontFamily: FONTS.sansSerif,
    fontSize: '18px',
    fontWeight: FONT_WEIGHTS.heavy,
    marginTop: '15px'
  },
  soldOut: {
    marginTop: '2rem'
  },
  description: {
    color: COLORS.darkBlue,
    fontSize: '20px',
    lineHeight: '25px',
    maxWidth: '100%',
    margin: 'auto',
    marginTop: '50px'
  }
}

const MerchProductInfo = ({ product, cartId, setCartId, userId, productType }) => {
  const [totalItems, setTotalItems] = useState(1)
  const [calculatedPrice, setCalculatedPrice] = React.useState(
    priceFormatter.format(product.price)
  )

  useEffect(() => {
    setCalculatedPrice(() =>
      priceFormatter.format(parseInt(product.price) * totalItems)
    )
  }, [totalItems])

  return (
    <>
      {!!product.quantity ? (
        <div>
          <h3 style={styles.price}>{calculatedPrice}</h3>
          <QuantityPicker
            maxQuantity={product.quantity}
            total={totalItems}
            setTotal={setTotalItems}
            requireSize={false}
          />

          <AddToCartButton
            cartId={cartId}
            setCartId={setCartId}
            userId={userId}
            product={{
              printName: product.printName,
              descName: product.descName,
              price: product.price,
              description: product.description,
              location: product.primaryImage,
              quantity: totalItems,
              cart_id: cartId,
              merchId: product.id,
              productType
            }}
          />

          {!!product.description && (
            <p style={styles.description}>{product.description}</p>
          )}
        </div>
      ) : (
        <div style={styles.soldOut}>
          <h3>So sorry! This item has already been sold.</h3>
        </div>
      )}

      <ReturnToPreviousButton />
    </>
  )
}

export default MerchProductInfo
