import React, { useEffect, useState } from 'react'
import axios from 'axios'
import SingleProduct from '../components/singleProduct/SingleProduct'
import { getUser } from '../utils/Session'
import LoadingSingleItem from '../components/singleProduct/shared/LoadingSingleItem'

interface Props {
  productType: string
}

const SingleProductPage = ({ productType }: Props) => {
  const [product, setProduct] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [userId, setUserId] = useState<number>()

  useEffect(() => {
    window.scrollTo(0, 0)

    const path = window.location.pathname
    const pathArr = path.split('/')
    const id = pathArr.pop()

    setIsLoading(true)

    const getProductAndUser = async () => {
      const retrievedUserId = await getUser().id
      setUserId(retrievedUserId)

      const productRes = await axios.get(`/api/${productType}/id/${id}`)

      if (productRes.status === 200) {
        setProduct(productRes.data)
      } else {
        console.error(productRes)
      }
      setIsLoading(false)
    }
    getProductAndUser()
  }, [])

  return isLoading ? (
  <LoadingSingleItem />
  ) : (
    <SingleProduct
      productType={productType}
      product={product}
      userId={userId}
    />
  )
}

export default SingleProductPage
