import React, { CSSProperties } from 'react'
import { FONTS, COLORS, FONT_SIZES, FONT_WEIGHTS } from '../../../constants/theme'
import Swal from 'sweetalert2'

const styles: { [key: string]: CSSProperties } | any = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '50px'
  },
  button: {
    backgroundColor: COLORS.darkBlue,
    color: COLORS.offWhite,
    width: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'end',
    fontWeight: FONT_WEIGHTS.heavy,
    paddingTop: '5px'
  },
  subtractBtn: {
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px'
  },
  addBtn: {
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px'
  },
  input: {
    color: COLORS.darkBlue,
    fontFamily: FONTS.sansSerif,
    fontWeight: FONT_WEIGHTS.heavy,
    fontSize: '20px',
    width: '45px',
    height: '35px',
    textAlign: 'center',
    borderRight: 'none',
    borderLeft: 'none',
    borderTop: `2px solid ${COLORS.darkBlue}`,
    borderBottom: `2px solid ${COLORS.darkBlue}`
  }
}

const QuantityPicker: any = ({ maxQuantity, total, setTotal, requireSize, size }) => {
  return (
    <div style={styles.wrapper}>
      <button
        style={{...styles.button, ...styles.subtractBtn}}
        onClick={() => {
          if (total > 1) {
            setTotal((prev: number) => prev - 1)
          }
        }}
      >
        -
      </button>
      <input
        type='number'
        value={total}
        min='1'
        max={maxQuantity}
        onChange={() => {}}
        style={styles.input}
      />
      <button
        style={{...styles.button, ...styles.addBtn}}
        onClick={() => {
          // fire alert for prints if size isn't selected
          if (requireSize && size === 'Select Size') {
            Swal.fire({
              icon: "warning",
              title: "<span>Please select print size.</span>",
              showConfirmButton: false,
              timer: 2000,
            })

            return
          }

          if (total < maxQuantity) {
            setTotal((prev: number) => prev + 1)
          }
        }}
      >
        +
      </button>
    </div>
  )
}

export default QuantityPicker
