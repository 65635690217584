export const PRINT_SIZES = {
  SQUARE: {
    small: '10 x 10"',
    medium: '14 x 14"',
    large: '18 x 18"'
  },
  PORTRAIT: {
    small: '12 x 18"',
    medium: '24 x 36"'
  }
}
