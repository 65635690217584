import React, { CSSProperties } from 'react'
import PropTypes from 'prop-types'
import { COLORS, FONTS, FONT_SIZES, FONT_WEIGHTS } from '../../constants/theme'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import useViewportType from '../../utils/useViewportType'
import { useHistory } from 'react-router-dom'

interface NewArrival {
  id: number
  location: string | undefined
  primaryImage: string | undefined
  dimensions: string | undefined
  quantity: string | undefined
  descName: string | undefined
}

interface Props {
  newArrivals: NewArrival[]
  doodleLocation: string
}

const styles: { [key: string]: CSSProperties } | any = {
  wrapper: (isMobile: boolean) => ({
    width: isMobile ? '87.5vw' : '80vw',
    margin: '40px auto'
  }),
  newArrivalsHeader: (isMobile: boolean) => ({
    fontFamily: FONTS.serif,
    fontWeight: FONT_WEIGHTS.medium,
    color: COLORS.darkBlue,
    fontSize: isMobile ? '32px' : FONT_SIZES.desktopHeader,
    marginBottom: '28px'
  }),
  newArrivals: (isMobile: boolean) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${isMobile ? 2 : 3}, 1fr)`,
    gridGap: isMobile ? '40px' : '40px 5vw',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 1
  }),
  newArrivalItem: {
    cursor: 'pointer',
    position: 'relative',
    width: '100%',
    height: 0,
    paddingTop: '100%',
    overflow: 'hidden'
  },
  newArrivalImage: (item: NewArrival) => ({
    backgroundImage: `url(${item?.location ?? item.primaryImage})`,
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }),
  parallaxDoodle: (isMobile: boolean, doodleLocation: string) => ({
    width: isMobile ? '200px' : '400px',
    height: isMobile ? '125px' : '250px',
    backgroundImage: `url(${doodleLocation})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    zIndex: -1
  })
}

const NewArrivals = ({ newArrivals, doodleLocation }: Props) => {
  const isMobile = useViewportType(700)
  const history = useHistory()

  const handleClick = (item: NewArrival) => {
    let uri = ''

    // print item
    if (item.dimensions) {
      uri = '/image/'

    // merch item
    } else if (item.quantity) {
      uri = '/merch/'
    
    // clothing item (descName required)
    } else if (item.descName) {
      uri = '/clothing/'
    }
    
    uri && history.push(`${uri}${item.id}`)
  }

  return (
    <section style={styles.wrapper(isMobile)}>
      {newArrivals.length && (
        <>
          <h1 style={styles.newArrivalsHeader(isMobile)}>
            Explore New Arrivals
          </h1>

          <div style={styles.newArrivals(isMobile)}>
            {newArrivals.map((item: NewArrival, idx: number) => (
              <div key={idx} style={styles.newArrivalItem} onClick={() => handleClick(item)}>
                <div style={styles.newArrivalImage(item)}></div>
              </div>
            ))}
          </div>
        </>
      )}

      <ParallaxProvider>
        <Parallax speed={isMobile ? 5 : 10} style={{ position: 'relative' }}>
          {/* placeholder for doodle */}
          <div style={styles.parallaxDoodle(isMobile, doodleLocation)}></div>
        </Parallax>
      </ParallaxProvider>
    </section>
  )
}

NewArrivals.propTypes = {
  newArrivals: PropTypes.array
}

export default NewArrivals
