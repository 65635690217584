import React from 'react'
import { FONTS, FONT_WEIGHTS, COLORS } from '../../../constants/theme'
import { priceFormatter } from '../../../utils/priceFormatter'
import AddToCartButton from '../shared/AddToCartButton'
import ReturnToPreviousButton from '../shared/ReturnToPreviousButton'

const styles = {
  price: {
    fontFamily: FONTS.sansSerif,
    fontSize: '18px',
    fontWeight: FONT_WEIGHTS.heavy,
    marginTop: '10px'
  },
  description: {
    color: COLORS.darkBlue,
    fontSize: '20px',
    lineHeight: '25px',
    maxWidth: '100%',
    margin: 'auto',
    marginTop: '50px'
  },
  careInstructions: {
    color: COLORS.orange,
    fontSize: '20px',
    lineHeight: '25px',
    maxWidth: '100%',
    margin: 'auto',
    marginTop: '50px'
  }
}

const ClothingInfo = ({ product, cartId, setCartId, userId, productType }) => {
  return (
    <>
      <h3 style={styles.price}>{priceFormatter.format(product.price)}</h3>
      <AddToCartButton
        cartId={cartId}
        setCartId={setCartId}
        userId={userId}
        product={{
          printName: product.printName,
          descName: product.descName,
          price: product.price,
          description: product.description,
          location: product.primaryImage,
          cart_id: cartId,
          clothingId: product.id,
          productType
        }}
      />

      {!!product.description && (
        <p style={styles.description}>{product.description}</p>
      )}

      <p style={styles.careInstructions}>
        Hand wash in cold water to protect this garment long term.
      </p>

      <ReturnToPreviousButton />
    </>
  )
}

export default ClothingInfo
