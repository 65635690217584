import React, { CSSProperties, useState } from 'react'
import { COLORS, FONT_WEIGHTS } from '../../../constants/theme'
import { PRINT_SIZES } from '../../../constants/printSizes'

const styles: { [key: string]: CSSProperties } | any = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    marginTop: '50px',
    position: 'relative',
    width: '200px'
  },
  button: {
    backgroundColor: COLORS.darkBlue,
    color: COLORS.offWhite,
    display: 'flex',
    justifyContent: 'space-around',
    fontWeight: FONT_WEIGHTS.heavy,
    padding: '8px 16px',
    borderRadius: '5px',
    width: '100%'
  },
  arrow: (isDiplayed: boolean) => ({
    width: '25px',
    height: '25px',
    transform: isDiplayed ? 'rotate(-180deg)' : '',
    transitionDuration: '500ms'
  }),
  dropDown: {
    backgroundColor: COLORS.darkBlue,
    color: COLORS.offWhite,
    width: 'inherit',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    position: 'absolute',
    zIndex: 1,
    cursor: 'pointer',
    marginTop: '2.2rem'
  },
  dropDownContent: {
    padding: '0 10px 10px'
  },
  li: (isHovered: boolean) => ({
    paddingTop: '10px',
    color: isHovered ? COLORS.yellow : COLORS.offWhite
  })
}

const SizePicker = ({ size, setSize, dimensions }) => {
  const [display, setDisplay] = useState(false)
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  return (
    <div style={styles.wrapper}>
      <button style={styles.button} onClick={() => setDisplay(!display)}>
        {size}
          <>
            <img
              style={styles.arrow(display)}
              src='../../assets/images/dropdown_arrow.svg'
              alt='Drop Down Arrow'
            />
          </>
      </button>
      <div style={{ display: display ? 'block' : 'none', ...styles.dropDown }}>
        <ul style={styles.dropDownContent}>
          {[
            {
              text:
                dimensions === 'square'
                  ? PRINT_SIZES.SQUARE.small
                  : PRINT_SIZES.PORTRAIT.small,
              action: () => {
                setSize(
                  dimensions === 'square'
                    ? PRINT_SIZES.SQUARE.small
                    : PRINT_SIZES.PORTRAIT.small
                )
                setDisplay(false)
              }
            },
            {
              text:
                dimensions === 'square'
                  ? PRINT_SIZES.SQUARE.medium
                  : PRINT_SIZES.PORTRAIT.medium,
              action: () => {
                setSize(
                  dimensions === 'square'
                    ? PRINT_SIZES.SQUARE.medium
                    : PRINT_SIZES.PORTRAIT.medium
                )
                setDisplay(false)
              }
            },
            dimensions === 'square' && {
              text: PRINT_SIZES.SQUARE.large,
              action: () => {
                setSize(PRINT_SIZES.SQUARE.large)
                setDisplay(false)
              }
            }
          ].map((item: any, index: number) => (
            <li
              key={index}
              style={styles.li(index === hoveredIndex)}
              onClick={item.action}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              {item.text}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default SizePicker
