import { Dispatch, SetStateAction } from 'react'
import { COLORS } from '../constants/theme'
import { Print, Clothing, Merch } from '../constants/products'
import axios from 'axios'
import Swal from 'sweetalert2'

const successAlert = async () => {
  return await Swal.fire({
    icon: 'success',
    iconColor: COLORS.success,
    title: '<span>Item added to cart!</span>',
    confirmButtonText: 'Go to cart',
    confirmButtonColor: COLORS.darkBlue,
    showCancelButton: true,
    cancelButtonText: 'Continue shopping',
    cancelButtonColor: COLORS.darkBlue
  })
    .then((res) => {
      if (res.isConfirmed) {
        return (window.location.pathname = '/cart')
      } else {
        return window.history.go(-1)
      }
    })
    .catch((err) => console.error(err))
}

const addItemToCart = async (data, productType) => {
  await axios({
    method: 'post',
    url: `/api/cart-${productType}/create`,
    headers: {},
    data
  }).then((res) => successAlert())
}

const createNewCart = async (data, productType, setCartId, userId) => {
  await axios({
    method: 'post',
    url: '/api/cart/create',
    headers: {},
    data: {
      user_id: userId
    }
  }).then(async (res) => {
    setCartId(res.data.cart?.id)
    const newCartId = parseInt(res.data.cart?.id)

    return await axios({
      method: 'post',
      url: `/api/cart-${productType}/create`,
      headers: {},
      data: { ...data, cart_id: newCartId }
    })
      .then((res) => successAlert())
      .catch((err) => {
        console.error(err)
        return Swal.fire({
          icon: 'warning',
          title: '<span>Error: Item not added to cart.</span>',
          showConfirmButton: false,
          timer: 1500
        })
      })
  })
}

const checkIfItemAlreadyInCart = async (cartId, product, productType) => {
  if (productType === 'prints' && product.size === 'Select Size') {
    return Swal.fire({
      icon: 'warning',
      title: '<span>Select print size</span>',
      showConfirmButton: false,
      timer: 1500
    })
  }

  return await axios
    .get(`/api/cart-${productType}/id/${cartId}`)
    .then((res): any => {
      for (let i = 0; i < res.data.length; i++) {
        switch (productType) {
          case 'clothing':
          case 'merch':
            if (
              res.data[i].printName === product.printName &&
              res.data[i].descName === product.descName
            ) {
              return Swal.fire({
                icon: 'warning',
                title: '<span>Item already in cart!</span>',
                showConfirmButton: false,
                timer: 1500
              })
            }
            break
          case 'prints':
            if (
              res.data[i]?.name === product?.name &&
              res.data[i]?.size === product?.size
            ) {
              return Swal.fire({
                icon: 'warning',
                title: '<span>Item already in cart!</span>',
                showConfirmButton: false,
                timer: 1500
              })
            }
            break
          default:
            console.error(`Unrecognized product type: ${productType}`)
        }
      }

      return addItemToCart(product, productType)
    })
}

export const loadCart = async (userId: number | undefined) => {
  // get most recent cart associated with user
  return await axios.get(`/api/cart/id/${userId}`).then((res) => {
    return res.data.mostRecentCart
  })
}

export const handleAddToCart = (
  cartId: number | undefined,
  product: Print | Clothing | Merch,
  productType: string,
  setCartId: Dispatch<SetStateAction<number>>,
  userId: number | undefined
) => {
  if (!userId) {
    return Swal.fire({
      icon: 'error',
      title: '<span>Ope. Something went wrong</span>',
      showConfirmButton: false,
      timer: 2500
    })
  } else {
    if (!cartId) {
      createNewCart(product, productType, setCartId, userId)
    } else {
      checkIfItemAlreadyInCart(cartId, product, productType)
    }
  }
}
