import React from "react";
import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import axios from "axios";
import "./style.css";
import { FONT_SIZES, LINE_HEIGHTS } from '../../../constants/theme'

const styles = {
  header: {
    fontSize: FONT_SIZES.desktopHeader
  },
  body: {
    fontSize: FONT_SIZES.desktopBody,
    lineHeight: LINE_HEIGHTS.desktopBody,
    maxWidth: '800px'
  }
}

function ClothingGalleryComponent() {
  const [galleryImages, setGalleryImages] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [vacationMode, setVacationMode] = useState<{
    vacationModeOn: boolean;
    message?: "string";
  }>({ vacationModeOn: false });

  useEffect(() => {
    // get existing clothing items
    axios
      .get("/api/clothing")
      .then((res) => {
        setGalleryImages(res.data);
      })
      .catch((err) => console.error(err));

    // check whether vacation mode is active
    axios
      .get("/api/vacation-mode", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsLoading(false);
        setVacationMode({
          vacationModeOn: res.data.vacationModeOn,
          message: res.data.message,
        });
      })
      .catch((err) => console.error(err));
  }, []);

  const history = useHistory();

  return (
    <section className="galleryWrapper">
      <h1 style={styles.header} className="galleryHeading">Clothing</h1>
      {!vacationMode.vacationModeOn ? (
        <>
          <h3 style={styles.body} className="galleryDesc">
            All clothing is printed by hand using unique designs and print
            placements. I screen print, heat press, relief print, and cyanotype
            for a range of textures and aesthetics. Like you, it's the only one
            in existence.
          </h3>
          {/* <h3 className="galleryDesc clothingGalleryDescRow2"></h3> */}
        </>
      ) : null}
      <div className="galleryContentWrapper">
        {/* conditionally render content to page */}
        {
          // if loading, show shimmer loading
          isLoading ? (
            <>
              <div className="galleryItemWrapper galleryClothingItemWrapper">
                <div className="galleryImage galleryClothingImage loadingItem"></div>
              </div>
              <div className="galleryItemWrapper galleryClothingItemWrapper">
                <div className="galleryImage galleryClothingImage loadingItem"></div>
              </div>
              <div className="galleryItemWrapper galleryClothingItemWrapper">
                <div className="galleryImage galleryClothingImage loadingItem"></div>
              </div>
              <div className="galleryItemWrapper galleryClothingItemWrapper">
                <div className="galleryImage galleryClothingImage loadingItem"></div>
              </div>
            </>
          ) : // else if vacation mode, show vacation mode message
          vacationMode.vacationModeOn ? (
            <>
              <section className="vacation-mode-section">
                <h3 className="vacation-mode-message">
                  {vacationMode.message}
                </h3>
              </section>
            </>
          ) : (
            // else show gallery content (if there's any items in stock)
            <>
              {galleryImages.length ? (
                galleryImages.map((el: any) => {
                  return (
                    <div
                      key={el.id}
                      className="galleryItemWrapper galleryClothingItemWrapper"
                    >
                      <div
                        className="galleryImage galleryClothingImage"
                        style={{
                          backgroundImage: "url(" + el.primaryImage + ")",
                        }}
                        onClick={() => history.push("clothing/" + el.id)}
                      ></div>
                      <h3
                        className="galleryClothingPrintName galleryClothingArtInfo"
                        onClick={() => history.push("clothing/" + el.id)}
                      >
                        {el.printName},
                        <span className="galleryClothingDescName">
                          {" " + el.descName}
                        </span>
                      </h3>
                      <h3 className="galleryArtPrice galleryClothingArtInfo">
                        ${el.price}
                      </h3>
                    </div>
                  );
                })
              ) : (
                <div className="sold-out-wrapper">
                  <h1 className="sold-out-heading">Ope!</h1>
                  <p className="sold-out-message">
                    All apparel is currently sold out. Keep an eye on my{" "}
                    <a
                      href="https://www.instagram.com/enchanted_quill/"
                      target={"_blank"}
                      className="sold-out-ig-link"
                    >
                      Instagram
                    </a>{" "}
                    story for updates, or check back here later.
                  </p>
                  <p className="sold-out-message">
                    More hand printed goodies to come - I apologize for the
                    inconvenience.
                  </p>
                </div>
              )}
            </>
          )
        }
      </div>
    </section>
  );
}

export default ClothingGalleryComponent;
