import React, { CSSProperties } from 'react'
import { useHistory } from 'react-router-dom'
import { COLORS } from '../../../constants/theme'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import { getRandomDoodles } from '../../../utils/getRandomDoodles'

const styles: { [key: string]: CSSProperties | any } = {
  wrapper: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center'
  },
  parallaxDoodle: (doodle) => ({
      backgroundImage: `url(${doodle})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      zIndex: -1,
      width: '200px',
      height: '200px',
      display: 'flex'
  }),
  rightDoodle: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    display: 'flex',
    padding: '10px 15px',
    border: `3px solid ${COLORS.darkBlue}`,
    borderRadius: '100px',
    backgroundColor: COLORS.offWhite,
    zIndex: 1
  },
  arrow: {
    transform: 'rotate(180deg)',
    width: '50px',
    marginRight: '10px',
    position: 'relative',
    top: '3px'
  }
}

const ReturnToPreviousButton = () => {
  const history = useHistory()
  const [doodles] = React.useState(getRandomDoodles())

  return (
    <ParallaxProvider>
      <Parallax speed={10}>
        {/* placeholder for doodle */}
        <div style={styles.parallaxDoodle(doodles[0])}></div>
      </Parallax>
      <div style={styles.wrapper}>
        <button onClick={() => history.goBack()} style={styles.button}>
          <img
            style={styles.arrow}
            src='../../assets/images/arrow.svg'
            alt='Back Arrow'
          />
          Return&nbsp;to&nbsp;Previous
        </button>
      </div>
      <Parallax speed={10} style={styles.rightDoodle}>
        {/* placeholder for doodle */}
        <div style={styles.parallaxDoodle(doodles[1])}></div>
      </Parallax>
    </ParallaxProvider>
  )
}

export default ReturnToPreviousButton
