export const BREAKPOINTS = {}

export const COLORS = {
  darkBlue: '#002c50',
  medBlue: '#00417e',
  offWhite: '#e6e9e0',
  orange: '#e85a22',
  success: '#41F2A0',
  yellow: '#f2cd3d'
}

export const FONTS = {
  serif: 'Averia Serif Libre, cursive',
  sansSerif: 'Roboto, sans-serif'
}

export const FONT_WEIGHTS = {
  light: 300,
  mediumLight: 400,
  medium: 500,
  heavy: 700
}

export const FONT_SIZES = {
  desktopHeader: '4rem',
  desktopBody: '1.5rem',
  desktopSubBody: '1.0rem' // TODO: this needs to be decided still
}

export const LINE_HEIGHTS = {
  desktopHeader: '4.5rem',
  desktopBody: '2rem'
}

