import React, { CSSProperties, useState } from 'react'
import SizePicker from './SizePicker'
import QuantityPicker from '../shared/QuantityPicker'
import AddToCartButton from '../shared/AddToCartButton'
import ReturnToPreviousButton from '../shared/ReturnToPreviousButton'
import { COLORS, FONTS, FONT_WEIGHTS } from '../../../constants/theme'
import { PRINT_SIZES } from '../../../constants/printSizes'
import { priceFormatter } from '../../../utils/priceFormatter'

const styles = {
  price: {
    fontFamily: FONTS.sansSerif,
    fontSize: '18px',
    fontWeight: FONT_WEIGHTS.heavy
  },
  description: {
    color: COLORS.darkBlue,
    fontSize: '20px',
    lineHeight: '25px',
    maxWidth: '100%',
    margin: 'auto',
    marginTop: '50px'
  }
}

const PrintInfo = ({ product, cartId, setCartId, userId, productType }) => {
  const [size, setSize] = useState('Select Size')
  const [totalItems, setTotalItems] = useState(1)
  const [calculatedPrice, setCalculatedPrice] = useState(product.smallPrice)
  const [individualPrintPrice, setIndividualPrintPrice] = useState(product.smallPrice)
  const [variantId, setVariantId] = useState(product.smallVariantId)

  React.useEffect(() => {
      switch (size) {
        case PRINT_SIZES.SQUARE.small:
        case PRINT_SIZES.PORTRAIT.small:
          setCalculatedPrice(product.smallPrice * totalItems)
          setIndividualPrintPrice(product.smallPrice)
          setVariantId(product.smallVariantId)
          break

        case PRINT_SIZES.SQUARE.medium:
        case PRINT_SIZES.PORTRAIT.medium:
          setCalculatedPrice(product.mediumPrice * totalItems)
          setIndividualPrintPrice(product.mediumPrice)
          setVariantId(product.mediumVariantId)
          break

        case PRINT_SIZES.SQUARE.large:
          setCalculatedPrice(product.largePrice * totalItems)
          setIndividualPrintPrice(product.largePrice)
          setVariantId(product.largeVariantId)
          break

        default:
          break
      }
  }, [size, totalItems])

  return (
    <>
      <h3 style={styles.price}>{priceFormatter.format(calculatedPrice)}</h3>
      <SizePicker
        size={size}
        setSize={setSize}
        dimensions={product.dimensions}
      />
      <QuantityPicker
        maxQuantity={Infinity} // maybe there's a better option than infinity here lol
        total={totalItems}
        setTotal={setTotalItems}
        requireSize={true}
        size={size}
      />

      <AddToCartButton
        cartId={cartId}
        setCartId={setCartId}
        userId={userId}
        product={{
          name: product.name,
          price: individualPrintPrice,
          size,
          variantId,
          description: product.description,
          location: product.location,
          quantity: totalItems,
          cart_id: cartId,
          productType
        }}
      />

      {!!product.description && (
        <p style={styles.description}>{product.description}</p>
      )}

      <ReturnToPreviousButton />
    </>
  )
}

export default PrintInfo
