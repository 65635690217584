import React from "react";
import { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import HeroComponent from "../components/HomePageComponents/HeroComponent";
import MostRecentLinks from "../components/HomePageComponents/MostRecentLinks";
import WhatsHot from "../components/HomePageComponents/WhatsHot";
import Footer from "../components/Footer";
import axios from "axios";

function HomePage(): any {
  const [hero, setHero] = useState<any>([]);
  // this is final array of most recent conent to be sent as props to MostRecentLinks component
  const [mostRecentFinalArr, setMostRecentFinalArr] = useState<any>([]);
  // state that contains each WhatsHot item
  const [whatsHot, setWhatsHot] = useState<any>([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    axiosRequests();
  }, []);

  const axiosRequests = async () => {
    // get hero image
    await axios
      .get("/api/hero")
      .then((res) => {
        // console.log(res);
        setHero(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });

    // variable for storing most recent most recent product information to be stored in mostRecentFinalArr
    let tempMostRecentFinalArr: string[] = [];

    // get 2 most recent prints (the second one is used for the Commissions link since it doesn't have an associated product in the database)
    await axios
      .get("/api/prints/recent")
      .then((res) => {
        tempMostRecentFinalArr.push(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // get most recent clothing item
    await axios
      .get("/api/clothing/recent")
      .then((res) => {
        tempMostRecentFinalArr.push(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // get most recent merch item
    await axios
      .get("/api/merch/recent")
      .then((res) => {
        tempMostRecentFinalArr.push(res.data);
        setMostRecentFinalArr(tempMostRecentFinalArr);
      })
      .catch((err) => {
        console.log(err);
      });

    // temp arr to be added to whatsHot state
    let tempWhatsHotArr: string[] = [];
    // get HotPrint
    await axios
      .get("/api/hot-print")
      .then((res: any) => {
        tempWhatsHotArr.push(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // get HotClothing
    await axios
      .get("/api/hot-clothing")
      .then((res: any) => {
        tempWhatsHotArr.push(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get("/api/hot-merch")
      .then((res: any) => {
        tempWhatsHotArr.push(res.data);
        setWhatsHot(tempWhatsHotArr.flat());
        // console.log('whatsHot: ', whatsHot);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="homepage-wrapper">
      <NavBar />
      <HeroComponent {...hero} />
      <MostRecentLinks {...mostRecentFinalArr} />
      <WhatsHot {...whatsHot} />
      <Footer />
    </div>
  );
}

export default HomePage;
