export const products = {
  PRINTS: 'prints',
  CLOTHING: 'clothing',
  MERCH: 'merch'
}

export interface Print {
  id: number
  name: string
  dimensions: 'square' | 'portrait'
  smallPrice: string
  mediumPrice: string
  largePrice: string
  description: string
  smallVariantId: string
  mediumVariantId: string
  largeVariantId: string
  location: string
  candidLocation: string
  createdAt: string
  updatedAt: string
}

export interface Clothing {
  id: number
  printName: string
  descName: string
  price: string
  description: string
  primaryImage: string
  secondaryImage: string
  supplementaryImage: string
  createdAt: string
  updatedAt: string
}

export interface Merch {
    id: number;
    printName: string;
    descName: string;
    price: string;
    description: string;
    primaryImage: string;
    secondaryImage: string;
    supplementaryImage: string;
    quantity: number;
    createdAt: string;
    updatedAt: string;
}
