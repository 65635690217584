import React from 'react'
import { FONTS, FONT_WEIGHTS } from '../../constants/theme'
import ClothingInfo from './clothingProduct/ClothingInfo'
import MerchProductInfo from './merchProduct/MerchInfo'
import PrintInfo from './printProduct/PrintInfo'

interface ClothingPriceProps {
  price: number
}

// return appropriate component
const SingleProductInfo = ({
  product,
  productType,
  cartId,
  setCartId,
  userId
}) => {
  switch (productType) {
    case 'clothing':
      return (
        <ClothingInfo
          cartId={cartId}
          setCartId={setCartId}
          userId={userId}
          product={product}
          productType={productType}
        />
      )
    case 'merch':
      return (
        <MerchProductInfo
          cartId={cartId}
          setCartId={setCartId}
          userId={userId}
          product={product}
          productType={productType}
        />
      )
    case 'prints':
      return (
        <PrintInfo
          cartId={cartId}
          setCartId={setCartId}
          userId={userId}
          product={product}
          productType={productType}
        />
      )
    default:
      return <></>
  }
}

export default SingleProductInfo
