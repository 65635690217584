import React, { CSSProperties } from 'react'
import useViewportType from '../../../utils/useViewportType'

const styles: { [key: string]: CSSProperties | any } = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    marginBottom: '25px'
  },
  hero: (isMobile: boolean) => ({
    width: isMobile ? '80vw' : '73vh',
    height: isMobile ? '80vw' : '73vh',
  }),
  button: (isMobile: boolean) => ({
    width: isMobile ? '200px' : '500px',
    height: '50px',
    borderRadius: '100px',
    marginTop: '50px',
    marginBottom: '30px'
  }),
  text: {
    width: '80vw',
    height: '20px',
    marginTop: '20px'
  },
  shimmer: {
    animationDuration: '2.2s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: 'shimmer',
    animationTimingFunction: 'linear',
    background: 'linear-gradient(to right, #E6E9E0 8%, #F0F0F0 18%, #E6E9E0 33%)',
    // background: 'linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%)',
    backgroundSize: '1200px 100%'
  },

  '@-webkit-keyframes shimmer': {
    '0%': {
      'background-position': '-100% 0',
    },
    '100%': {
      'background-position': '100% 0'
    }
  },
  '@keyframes shimmer': {
    '0%': {
      'background-position': '-1200px -1200px'
    },
    '100%': {
      'background-position': '1200px 1200px'
    }
  }
}

const LoadingSingleItem = () => {
  const isMobile = useViewportType(900)

  return (
    <div style={styles.wrapper}>
      {/* hero image */}
      <div style={{...styles.hero(isMobile), ...styles.shimmer}}></div>

      {/* small rectangle box like add to cart button */}
      <div style={{...styles.button(isMobile), ...styles.shimmer}}></div>

      {/* few thin long boxes looking like text */}
      <div style={{...styles.text, ...styles.shimmer}}></div>
      <div style={{...styles.text, ...styles.shimmer}}></div>
      <div style={{...styles.text, ...styles.shimmer}}></div>
    </div>
  )
}

export default LoadingSingleItem
